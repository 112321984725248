import axios from 'axios'
import * as Sentry from '@sentry/vue'
// import VueYandexMetrika from 'vue3-yandex-metrika'

// import router from '@/router'
import { useMainStore } from '@/stores'
import { BASE_URL } from '@/configs/urls'

export default (app) => {
  const { MODE, VITE_APP_RELEASE_VERSION } = import.meta.env
  const mainStore = useMainStore()

  axios
    .get(`${BASE_URL}/api/config`)
    .then(({ data }) => {
      const {
        sentry_url,
        deploy_env,
        sentry_deployment_tag_name,
        sentry_deployment_id,
        license,
        max_users,
        max_upload_size
      } = data

      mainStore.setLicenseAndUsersCount(license, max_users)
      mainStore.setMaxUploadSize(max_upload_size)

      if (!mainStore.isOnline || MODE === 'development') return

      if (sentry_url) {
        Sentry.init({
          app,
          dsn: sentry_url,
          integrations: [
            new Sentry.BrowserTracing({
              tracePropagationTargets: ['localhost', /^\//]
            }),
            Sentry.replayIntegration()
          ],
          tracesSampleRate: 1.0,
          environment: deploy_env,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          release: VITE_APP_RELEASE_VERSION || window.location.host
        })

        Sentry.setTag(sentry_deployment_tag_name, sentry_deployment_id)
      }

      // app.use(VueYandexMetrika, {
      //   id: 92854208,
      //   router: router,
      //   env: MODE,
      //   options: {
      //     clickmap: true,
      //     trackLinks: true,
      //     accurateTrackBounce: true
      //   }
      // })
    })
    .catch((error) =>
      console.log('Не удалось загрузить конфиг и запустить сервисы мониторинга', error)
    )
}
