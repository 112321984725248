<script setup>
import { ref } from 'vue'
import CreateSoilInfo from './soil-info.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    default: () => {}
  }
})

const depthConfig = ref({
  id: 'depth',
  title: 'foot_d',
  label: 'Глубина подошвы, м',
  required: true,
  min: 0,
  max: 12262,
  type: 'number',
  placeholder: '0.6'
})

const hasError = () => {
  return props.soilInterval.to <= props.soilInterval.from
}
</script>

<template>
  <div class="create-guide-depth">
    <div class="create-guide-depth-row">
      <s-input-renderer :field="depthConfig" :source="source" />
    </div>
    <create-soil-info
      v-if="source.foot_d || source.foot_d === 0"
      :data="source"
      :type="hasError() ? 'warning' : ''"
      :soil-interval="soilInterval"
    />
  </div>
</template>

<style lang="scss">
.create-guide-depth {
  display: grid;
  grid-gap: 1rem;
}
</style>
