<script setup>
import { computed, ref } from 'vue'
import { useAuthStore, useExcavationStore } from '@/stores'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  source: {
    type: Object,
    default: () => null
  },
  services: {
    type: Object,
    default: () => null
  },
  warning: {
    type: Boolean,
    default: false
  },
  required: {
    type: Array,
    default: () => []
  },
  activeId: {
    type: [Number, String],
    default: null
  },
  isCreate: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['input', 'change', 'has-error'])

const authStore = useAuthStore()
const excavationStore = useExcavationStore()

const initSource = ref(props.source)

const hasError = (value) => {
  return props.required?.includes(value)
}

const getColorType = () => {
  if (props.warning) return 'error'

  const { fields } = props.item
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return filledLength === length ? 'success' : 'default'
}

const getHeaderCount = () => {
  const { fields } = props.item
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return `${filledLength} / ${length}`
}

const changeActiveCollapse = (id) => {
  emits('change', id)
}

const isFieldDisabled = (field) => {
  if (authStore.isAdmin) return false

  if (field.title === 'h_plan' && !props.isCreate) {
    return excavationStore?.soilsList?.length > 0
  }

  return false
}

const getSyncText = computed(() => {
  const changedFields = props.source.sync_progress.join(', ')
  return `В процессе синхронизации находятся следующие данные: ${changedFields}`
})

const isShowSyncStatusWarning = computed(() => {
  return (
    props.item.value === 'basic' &&
    props.source?.sync_progress?.length &&
    props.source?.sync_status === 1
  )
})

const handleError = (value) => {
  emits('has-error', value)
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    :id="item.id"
    :title="item.title"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="warning"
        name="circle-exclamation"
        size="sm"
        class="header-stats__icon"
        color="var(--error)"
      />
      <s-text :type="getColorType()">
        {{ getHeaderCount() }}
      </s-text>
    </template>
    <s-input-renderer
      v-for="field in item.fields"
      :key="field.id"
      :field="field"
      :source="initSource"
      :disabled="isFieldDisabled(field)"
      :services="services"
      @has-error="handleError"
      :error="hasError(field.title)"
      :is-create="isCreate"
    />

    <s-alert
      class="collapse-item__sync-alert"
      v-if="isShowSyncStatusWarning"
      icon="circle-info"
      type="primary"
    >
      <s-text> {{ getSyncText }}</s-text>
    </s-alert>
  </s-collapse-item>
</template>

<style lang="scss">
.collapse-item {
  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__sync-alert {
    grid-column: span 2;
  }
}
</style>
