<script setup>
import { ref, computed, inject } from 'vue'
import { useMapStore } from '@/stores'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'
import SMapLocationModal from '@/components/s-location-editor/s-map-location-modal.vue'

const props = defineProps({
  locationFields: {
    type: Object
  },
  locationFactFields: {
    type: Object
  },
  source: {
    type: Object,
    default: () => null
  },
  warning: {
    type: Boolean,
    default: false
  },
  required: {
    type: Array,
    default: () => []
  },
  newItem: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => null
  },
  activeId: {
    type: [Number, String],
    default: null
  }
})

const emits = defineEmits(['change', 'has-error'])

const $notify = inject('$notify')
const mapStore = useMapStore()

const initSource = ref(props.source)
const modal = ref(false)

const userLocation = computed(() => {
  return mapStore.userLocation || null
})

const userAbs = computed(() => {
  return mapStore.userAbs || null
})

const sourceCenter = computed(() => {
  const { lon: lonValue, lat: latValue } = initSource.value

  if (lonValue && latValue) return [lonValue, latValue]

  const { lon_plan, lat_plan } = initSource.value

  if (lon_plan && lat_plan) return [lon_plan, lat_plan]

  if (!userLocation.value) return [37.6165, 55.752]

  const [lon, lat] = userLocation.value

  return [lon, lat]
})

const hasError = (value) => {
  return props.required?.includes(value)
}

const getColorType = () => {
  if (props.warning) return 'error'

  const fields = props.newItem ? props.locationFields : props.locationFactFields
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return filledLength === length ? 'success' : 'default'
}

const getHeaderCount = () => {
  const fields = props.newItem ? props.locationFields : props.locationFactFields
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return `${filledLength} / ${length}`
}

const setCurrentLocation = () => {
  if (!userLocation.value) {
    $notify({
      message: 'Геопозиция не определена. Проверьте настройки GPS на устройстве',
      type: 'warning'
    })
  } else {
    const [lon, lat] = userLocation.value

    if (props.newItem) {
      initSource.value.lon_plan = lon
      initSource.value.lat_plan = lat

      if (userAbs.value) {
        initSource.value.abs_plan = userAbs.value
      }
    } else {
      initSource.value.lon = lon
      initSource.value.lat = lat

      if (userAbs.value) {
        initSource.value.abs = userAbs.value
      }
    }
  }
}

const setMapCenter = (center) => {
  const [lon, lat] = center

  if (props.newItem) {
    initSource.value.lon_plan = Number(lon?.toFixed(5))
    initSource.value.lat_plan = Number(lat?.toFixed(5))
  } else {
    initSource.value.lon = Number(lon?.toFixed(5))
    initSource.value.lat = Number(lat?.toFixed(5))
  }
}

const toggleModal = () => {
  modal.value = !modal.value
}

const changeActiveCollapse = (id) => {
  emits('change', id)
}

const handleError = (value) => {
  emits('has-error', value)
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    id="location"
    title="Местоположение и отметки"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="warning"
        name="circle-exclamation"
        size="sm"
        class="header-stats__icon"
        color="var(--error)"
      />
      <s-text :type="getColorType()">
        {{ getHeaderCount() }}
      </s-text>
    </template>
    <div :class="['s-map-editor-location-row', { disabled: !newItem }]">
      <div class="s-map-editor-location-row__coords__type">
        <s-title type="small"> Планируемое, </s-title>
        <s-text>WGS 84</s-text>
      </div>
      <s-input-renderer
        v-for="field in locationFields"
        :key="field.id"
        :field="field"
        :half="field.half"
        :source="initSource"
        :disabled="!newItem"
        :error="hasError(field.title)"
        @has-error="handleError"
      />
      <s-button v-if="newItem" @click="setCurrentLocation">По GPS</s-button>
      <s-button v-if="newItem" type="primary" @click="toggleModal">На карте</s-button>
    </div>
    <div v-if="!newItem" class="s-map-editor-location-row">
      <div class="s-map-editor-location-row__coords__type">
        <s-title type="small"> Фактическое, </s-title>
        <s-text>WGS 84</s-text>
      </div>
      <s-input-renderer
        v-for="field in locationFactFields"
        :key="field.id"
        :field="field"
        :half="field.half"
        :source="initSource"
        :error="hasError(field.title)"
        @has-error="handleError"
      />
      <s-button @click="setCurrentLocation">По GPS</s-button>
      <s-button type="primary" @click="toggleModal">На карте </s-button>
    </div>
    <s-map-location-modal
      v-if="modal"
      :template="source"
      :is-visible="modal"
      :new-item="newItem"
      :center="sourceCenter"
      :item="item"
      @toggle="toggleModal"
      @save="setMapCenter"
    />
  </s-collapse-item>
</template>

<style lang="scss">
.collapse-item {
  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.s-map-editor-location {
  &-row {
    display: grid;
    grid-gap: 1rem;
  }
}
.s-map-editor-location-row {
  &__coords__type {
    grid-column: span 2;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
