<script setup>
import { ref, computed, inject } from 'vue'
import { useAuthStore } from '@/stores'
import SAttachmentsVideoGallery from './s-attachments-video-gallery.vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: null
  }
})

const emits = defineEmits(['remove'])

const $confirm = inject('$confirm')
const authStore = useAuthStore()

const showGallery = ref(false)
const loading = ref(false)
const initialSlideIndex = ref(0)

const openGallery = async (index) => {
  if (props.type === 'editor') return

  initialSlideIndex.value = index
  showGallery.value = !showGallery.value
}

const removeFile = (item) => {
  const message = 'Вы точно хотите удалить видео?'
  const title = 'Удаление видео'

  $confirm(message, title, {
    confirmButtonText: 'Удалить',
    cancelButtonText: 'Отменить',
    confirmButtonClass: 'error'
  })
    .then(() => emits('remove', item))
    .catch(() => {})
}

const onLoadedMetadata = (event) => {
  const videoElement = event.target

  if (!isNaN(videoElement.duration) && videoElement.duration > 1) {
    videoElement.currentTime = 1
  } else {
    videoElement.currentTime = 0
  }

  videoElement.pause()
}

const listStyles = computed(() => {
  if (props.type === 'editor')
    return {
      'overflow-x': 'auto'
    }

  return {
    'flex-wrap': 'wrap'
  }
})

const styles = computed(() => {
  if (props.type === 'editor')
    return {
      padding: '0.5rem',
      cursor: 'default'
    }

  return {
    height: '64px',
    cursor: 'pointer'
  }
})

const getThumbnail = (item) => {
  return `${item.thumbnail_url}?access_token=${authStore.userToken}` || item.preview
}
</script>

<template>
  <div v-if="items.length" class="s-attachments-video">
    <div class="s-attachments-video__list" :style="listStyles">
      <div
        class="s-attachments-video__list-item"
        :style="styles"
        v-for="(item, index) in items"
        :key="item.title"
        @click.stop="openGallery(index)"
      >
        <video
          v-if="item.preview"
          class="s-attachments-video__preview"
          :src="item.preview"
          preload="metadata"
          muted
          autoplay
          playsinline
          @loadedmetadata="onLoadedMetadata"
        />
        <img
          v-else
          class="s-attachments-video__preview"
          :src="getThumbnail(item)"
          :alt="item.description"
        />
        <div class="s-attachments-video__overlay" v-if="type !== 'editor'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
            />
          </svg>
        </div>
        <s-button
          v-if="type === 'editor'"
          class="s-attachments-video__delete-btn"
          circle
          icon="times"
          size="sm"
          @click.stop="removeFile(item)"
        />
      </div>
    </div>
  </div>

  <s-attachments-video-gallery
    v-if="type !== 'editor'"
    :initialSlide="initialSlideIndex"
    :isVisible="showGallery"
    :thumbnails="true"
    :loading="loading"
    @video-ready="loading = false"
    :items="items"
    :thumbnailsSlidesPerView="10"
    @close="showGallery = false"
  />
</template>

<style lang="scss" scoped>
.s-attachments-video {
  display: grid;
  grid-gap: 0.5rem;
  border-radius: var(--border-radius);

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--overlay);
    border-radius: var(--border-radius);

    & svg {
      width: 32px;
      height: 32px;
      fill: var(--text);
    }
  }

  &__list {
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    position: relative;
    gap: 8px;

    &-item {
      position: relative;
    }
  }

  &__preview {
    height: 64px;
    z-index: 1;
    width: 64px;
    object-fit: cover;
    border-radius: var(--border-radius);
    background-color: var(--secondary-bg);
    border: 1px solid var(--main-bg);
  }

  &__delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px !important;
    height: 24px !important;
    min-height: auto !important;
  }
}
</style>
