<script setup>
import { computed, ref } from 'vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  warning: {
    type: Boolean,
    default: false
  },
  activeId: {
    type: [Number, String],
    default: null
  }
})

const emits = defineEmits(['change', 'has-error'])

const depthField = ref({
  id: 'depth',
  title: 'foot_d',
  label: 'Глубина подошвы, м',
  required: true,
  clearable: false,
  type: 'number',
  min: 0,
  max: 12262,
  placeholder: '0.6'
})

const hasError = computed(() => {
  return props.soilInterval.to <= props.soilInterval.from
})

const getInfo = () => {
  const from = Number(props.soilInterval.from) ? Number(props.soilInterval.from)?.toFixed(1) : '0.0'
  const to = Number(props.soilInterval?.to) ? Number(props.soilInterval.to)?.toFixed(1) : '0.0'
  const depth = `${from} - ${to} м`
  const power = (
    Number(props.soilInterval.to)?.toFixed(1) - Number(props.soilInterval.from)?.toFixed(1)
  )?.toFixed(1)

  return `Слой от ${depth}, мощность слоя - ${power}`
}

const changeActiveCollapse = (id) => {
  emits('change', id)
}

const handleError = (value) => {
  emits('has-error', value)
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    :id="depthField.id"
    :title="'Глубина залегания'"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="hasError"
        name="circle-exclamation"
        size="sm"
        color="var(--error)"
        class="header-stats__icon"
      />
      <s-text :type="hasError ? 'error' : source.foot_d ? 'success' : 'default'">
        {{ source.foot_d ? '1 / 1' : '0 / 1' }}
      </s-text>
    </template>
    <div class="soil-depth">
      <s-input-renderer :field="depthField" :source="source" @has-error="handleError" />
      <s-text :type="hasError ? 'error' : 'default'"> {{ getInfo() }} </s-text>
    </div>
  </s-collapse-item>
</template>

<style>
.soil-depth {
  display: grid;
  grid-gap: 1rem;
}
</style>
