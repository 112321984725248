export const excavationTemplate = {
  title: null,
  type: 101,

  h_plan: null,
  geologist: null,
  status: 1,
  site: null,
  comments: null,

  lat_plan: null,
  lon_plan: null,
  lat: null,
  lon: null,
  abs: null,
  abs_plan: null,

  date_from: Date.now(),
  date_to: null,

  excav_type_tool: null,
  excav_type_casing: null,
  excav_type_backfill: null,
  diam1: null,
  diam2: null,
  bore_machine: null,
  bore_master: null,

  geomorph_id: null,
  geomorph_comments: null,
  natural_f: null,
  artificial_f: null,
  sync_status: 0,
  sync_progress: []
}

export const basicFields = [
  {
    id: 1,
    title: 'title',
    label: 'Номер',
    required: true,
    type: 'string',
    placeholder: 'Т-100'
  },
  {
    id: 2,
    title: 'h_plan',
    label: 'Глубина (план), м',
    min: 0,
    max: 12262,
    caption: '',
    required: true,
    type: 'number',
    placeholder: '25'
  },
  {
    id: 3,
    title: 'geologist',
    label: 'Геолог',
    type: 'select',
    service: 'object_users'
  },
  {
    id: 4,
    title: 'status',
    label: 'Статус',
    required: true,
    type: 'select',
    service: 'statuses'
  },
  {
    id: 5,
    title: 'site',
    label: 'Участок работ',
    type: 'string',
    placeholder: 'ПАД'
  },
  {
    id: 6,
    title: 'comments',
    label: 'Комментарий к скважине',
    type: 'textarea',
    autocompleteTags: {
      tags: ['оборудована инклинометром', 'оборудована пьезометром']
    }
  },
  {
    id: 7,
    title: 'sync_status',
    label: 'Статус синхронизации',
    type: 'select',
    service: 'sync_statuses',
    concat_by: 'sync_progress'
  }
]

export const locationFields = [
  {
    id: 7,
    title: 'lat_plan',
    label: 'Широта',
    required: true,
    type: 'number',
    placeholder: '55.663',
    width: 'half',
    min: -90,
    max: 90
  },
  {
    id: 8,
    title: 'lon_plan',
    label: 'Долгота',
    required: true,
    type: 'number',
    placeholder: '-37.658',
    width: 'half',
    min: -180,
    max: 180
  },
  {
    id: 9,
    title: 'abs_plan',
    label: 'Абсолютная отметка, м',
    type: 'number',
    placeholder: '156.42',
    min: 0,
    max: 12262
  }
]

export const dateFields = [
  {
    id: 10,
    title: 'date_from',
    label: 'Начало',
    placeholder: '01.01.2022',
    type: 'date',
    dateOptions: {
      min: null,
      max: null,
      condition: {
        field: 'date_to',
        value: 'less'
      }
    }
  },
  {
    id: 11,
    title: 'date_to',
    label: 'Окончание',
    placeholder: '01.01.2022',
    type: 'date',
    dateOptions: {
      min: null,
      max: null,
      condition: {
        field: 'date_from',
        value: 'more'
      }
    }
  }
]

export const drillFields = [
  {
    id: 12,
    title: 'method',
    label: 'Тип бурения',
    type: 'select',
    service: 'methods'
  },
  {
    id: 13,
    title: 'casing',
    label: 'Крепление / обсадка',
    type: 'select',
    service: 'casings'
  },
  {
    id: 19,
    title: 'casing_meters',
    label: 'Глубина обсадки, м',
    min: 0,
    max: 12262,
    type: 'number',
    placeholder: '25'
  },
  {
    id: 14,
    title: 'backfill',
    label: 'Засыпка / тампонаж',
    type: 'select',
    service: 'backfills'
  },
  {
    id: 15,
    title: 'diam1',
    label: 'Начальный диаметр, мм',
    placeholder: '168',
    type: 'number',
    min: 0
  },
  {
    id: 16,
    title: 'diam2',
    label: 'Конечный диаметр, мм',
    placeholder: '96',
    type: 'number',
    min: 0
  },
  {
    id: 17,
    title: 'bore_machine',
    label: 'Буровая установка',
    type: 'select',
    service: 'object_bore_machines'
  },
  {
    id: 18,
    title: 'bore_master',
    label: 'Буровой мастер',
    type: 'select',
    service: 'object_bore_masters'
  }
]

export const natureFields = [
  {
    id: 19,
    title: 'geomorph_id',
    label: 'Геоморфология',
    type: 'select',
    service: 'geomorphies',
    wikiTipData: {
      color: 'placeholder',
      title: 'Геоморфология',
      description:
        'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: положение выработки в рельефе (на неосвоенных территориях)',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  },
  {
    id: 20,
    title: 'geomorph_comments',
    label: 'Комментарий к геоморфологии',
    type: 'textarea',
    rows: 4
  },
  {
    id: 21,
    title: 'natural_f',
    label: 'Природные условия',
    type: 'string',
    wikiTipData: {
      color: 'placeholder',
      title: 'Природные условия',
      description:
        'При описании горных выработок на многолетнемерзлых грунтах проводят описание растительного и снежного покровов (при наличии) на момент проходки, дают характеристику положения выработки в рельефе, глубину сезонного промерзания (оттаивания) на момент бурения.',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.6. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  },
  {
    id: 22,
    title: 'artificial_f',
    label: 'Техногенные условия',
    type: 'string',
    wikiTipData: {
      color: 'placeholder',
      title: 'Техногенные условия',
      description:
        'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: координаты или ситуационная привязка (на застроенных территориях)',
      source:
        'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
    }
  }
]

export const locationFactFields = [
  {
    id: 23,
    title: 'lat',
    label: 'Широта',
    required: true,
    type: 'number',
    placeholder: '55.663',
    width: 'half',
    min: -90,
    max: 90
  },
  {
    id: 24,
    title: 'lon',
    label: 'Долгота',
    required: true,
    type: 'number',
    placeholder: '-37.658',
    width: 'half',
    min: -180,
    max: 180
  },
  {
    id: 25,
    title: 'abs',
    label: 'Абсолютная отметка, м',
    type: 'number',
    placeholder: '156.42',
    min: 0,
    max: 12262
  }
]

export const fieldsList = [
  {
    id: 2,
    title: 'Даты проведения работ',
    value: 'date',
    fields: dateFields
  },
  {
    id: 3,
    title: 'Информация о бурении',
    value: 'drill',
    fields: drillFields
  },
  {
    id: 4,
    title: 'Природные условия',
    value: 'nature',
    fields: natureFields
  }
]

export const basicItem = {
  id: 1,
  title: 'Базовая информация',
  value: 'basic',
  fields: basicFields
}

export const excavationImageTemplate = () => ({
  image_b64: null,
  title: null,
  comments: null
})
